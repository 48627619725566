import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FilterContext } from "../../providers/FilterProvider"
import { device } from "../../theme"

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 22px;
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
`

const StyledLi = styled.li`
  margin-left: 4px;
  transition: transform 0.1s ease-in-out;
`

const StyledLink = styled(Link)`
  color: #444;
  font-size: 1rem;
  transition: padding-left 0.1s linear;
  &.selected-category {
    color: ${props => props.theme.colors.green};
    padding-left: 4px;
  }

  &:hover {
    color: ${props => props.theme.colors.green};
    padding-left: 4px;
  }
`

function Category({ gender }) {
  const { dispatch } = useContext(FilterContext)
  const categories = [
    { category: "T-shirts", url: `/${gender}/tshirts` },
    { category: "Jakker", url: `/${gender}/jakker` },
    { category: "Bukser", url: `/${gender}/bukser` },
    { category: "Skjorter", url: `/${gender}/skjorter` },
    { category: "Trøjer", url: `/${gender}/trojer` },
    { category: "Tasker", url: `/${gender}/tasker` },
    { category: "Sport", url: `/${gender}/sport` },
    { category: "Sko", url: `/${gender}/sko` },
    { category: "Undertøj", url: `/${gender}/undertoj` },
    { category: "Accessories", url: `/${gender}/accessories` },
  ]

  const handleCategory = () => dispatch({ resetState: true })

  return (
    <StyledUl>
      {categories.map(category => (
        <StyledLi onClick={() => handleCategory()} key={category.url}>
          <StyledLink activeClassName="selected-category" to={category.url}>
            {category.category}
          </StyledLink>
        </StyledLi>
      ))}
      {gender === "dame" && (
        <StyledLink activeClassName="selected-category" to="/dame/festtoppe">
          Festtoppe
        </StyledLink>
      )}
    </StyledUl>
  )
}

export default Category
