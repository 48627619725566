import React from "react"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { device } from "../../theme"

const Container = styled.div`
  height: 620px;
  width: calc(100vw - 44px);
  position: relative;

  @media ${device.phone} {
    height: 520px;
  }
`

const OfferHighlight = styled.span`
  color: ${props => props.theme.colors.green};
`

const SecondText = styled.div`
  position: absolute;
  top: 50%;
  left: 60px;
  width: 420px;
  background: #fcfcfc;
  transform: translateY(-50%);
  padding: 22px;
  z-index: 1;

  h2 {
    font-size: 1.8rem;
    margin: 0;
  }

  p {
    font-size: 1rem;
  }

  @media ${device.tablet} {
    top: 70%;
    left: 0;
  }

  @media ${device.phone} {
    top: 60%;
    left: 0;
    width: 100%;
  }
`

const ImageContainer = styled.div`
  height: 620px;
  display: block;
  position: absolute;
  top: -60px;
  right: 0;
  max-width: 80vw;
  width: 100%;
  @media ${device.phone} {
    max-width: 100%;
    height: 490px;
    right: -22px;
  }
`

function SecondView() {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "guy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <SecondText>
        <Fade left>
          <h2 style={{ margin: "12px 0" }}>
            Aldrig har <OfferHighlight>shopping</OfferHighlight> været{" "}
            <OfferHighlight>nemmere</OfferHighlight>
          </h2>
          <p style={{ fontSize: 18 }}>
            Slip for besværet, med at lede fra webshop til webshop.
            <br /> Med Lineout, har du forhandlerne samlet og du kan nu finde,
            lige det tøj du leder efter. Du kan se hvilke forhandlere du kan
            finde tøj fra, længere nede her på siden.
          </p>
        </Fade>
      </SecondText>
      <ImageContainer>
        <Img
          style={{ height: "100%" }}
          fluid={data.placeholderImage.childImageSharp.fluid}
        />
      </ImageContainer>
    </Container>
  )
}

export default SecondView
