const handleCharacters = str => {
  if (!str) return

  let input = str
  if (str && typeof str !== "string") {
    input = str[0]
  }

  return input
    .replace(/&#39;&#39;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&amp;/g, "&")
    .replace(/âŽ®/g, "|")
    .replace(/Ã«/g, "ë")
    .replace(/&quot;/g, '"')
    .replace(/Ãº/g, "ú")
}

module.exports.handleCharacters = handleCharacters
