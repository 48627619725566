import React, { useContext, useEffect } from "react"
import Layout from "../components/Layout"
import FirstView from "../components/FirstView"
import Brands from "../components/Brands"
import { FilterContext } from "../providers/FilterProvider"
import SecondView from "../components/SecondView"
import { initialState } from "../reducer"
import Description from "../components/Description"
import Offers from "../components/Offers"

const IndexPage = () => {
  const { dispatch } = useContext(FilterContext)

  useEffect(() => {
    dispatch(initialState)
  }, [])

  return (
    <>
      <Layout withStores>
        <FirstView />
        <SecondView />
        {/* <Description /> */}
        <Offers gender="men" />
        <div style={{ margin: '32px 0'}} />
        <Offers gender="women" />
      </Layout>
    </>
  )
}

export default IndexPage
