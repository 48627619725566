import React from "react"
import styled from "styled-components"
import { device } from "../../theme"

const Container = styled.div`
  margin: 0;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.phone} {
      padding: 32px 0;
  }
`

const Title = styled.h3`
  font-size: 2.5rem;
  margin-bottom: 0;
`

const TextBox = styled.div`
  padding: 12px 0;
  max-width: 570px;
`

const Text = styled.p`
  font-size: 1.2rem;
  @media ${device.phone} {
    font-size: 1rem;
}
`

function Description() {
  return (
    <Container>
      <Title>Dit online outlet</Title>
      <TextBox>
        <Text>
          Vi forsøger at samle et kæmpe udvalg af tøj, for at gøre det nemt for
          dig, at finde lige det tøj, du leder efter. I stedet for at skifte fra
          butik til butik, samler vi tøj fra flere forhandlere - uden du behøver
          at skifte butik. Dette er vores formål, at gøre det nemmere for dig at
          shoppe. Vi er ikke selv en webshop og vi ejer heller ikke nogle af
          produkter. Alt tøj på Lineout, kommer fra samarbejde med de forskellige butikker.
        </Text>
      </TextBox>
    </Container>
  )
}

export default Description
