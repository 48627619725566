import React, { useState } from "react"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { device } from "../../theme"
import { Icon } from "semantic-ui-react"
import SlideIn from "../SlideIn"

const Container = styled.div`
  height: 620px;
  width: 100vw;
  position: relative;
  @media ${device.phone} {
    height: 490px;
  }

  .logo {
    color: ${props => props.theme.colors.green};
    font-family: "Bebas Neue", cursive !important;
  }
`

const Image = styled(Img)`
  display: block;
  height: 100%;
  max-width: 90vw;

  @media ${device.phone} {
    max-width: calc(100vw - 22px);
    transform: translateX(-22px);
  }
`

const Buttons = styled.div`
  display: flex;
  margin: 0 0 12px;
`

const TextContainer = styled.div`
  position: absolute;
  top: 60%;
  padding-left: ${props => (props.isOpen ? "340px" : "42px")};
  transition: padding-left 0.2s ease-in;
  transform: translateY(-50%);

  h1 {
    color: #fff;
    font-size: 2.3rem;
    margin: 12px 0;
    text-shadow: 0 0 1px #000;
  }

  @media ${device.tablet} {
    padding-left: 22px;
  }

  @media ${device.phone} {
    padding-left: 6px;
  }
`

const ExtendButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  color: #fff;
  padding: 12px;
  border: 2px solid;
  border-color: ${props => (props.active ? props.theme.colors.green : "#fff")};
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  outline: none;
  font-weight: bold;
  margin-right: 8px;
`

function FirstView() {
  const [isExtended, setExtended] = useState({ men: false, women: false })
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "storeimage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Container>
        <Image fluid={data.placeholderImage.childImageSharp.fluid} />
        <TextContainer isOpen={isExtended.men || isExtended.women}>
          <h1>
            Butikkernes udvalg.
            <br /> Samlet <span style={{ color: "#ff6347" }}>samme</span> sted.
          </h1>
          <Buttons>
            <ExtendButton
              active={isExtended.men}
              onClick={() =>
                setExtended({ men: !isExtended.men, women: false })
              }
            >
              Herre
            </ExtendButton>
            <ExtendButton
              active={isExtended.women}
              onClick={() =>
                setExtended({ men: false, women: !isExtended.women })
              }
            >
              Dame
            </ExtendButton>
          </Buttons>
        </TextContainer>
      </Container>
      <SlideIn isOpen={isExtended} setExtended={setExtended} />
    </>
  )
}

export default FirstView
