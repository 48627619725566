import { Link } from "gatsby"
import React from "react"
import { Accordion, Icon, Menu } from "semantic-ui-react"
import styled from "styled-components"
import { device } from "../../theme"
import Category from "../Sidebar/category"

const Container = styled.aside`
  position: fixed;
  background: #fcfcfc;
  top: 75px;
  left: 0;
  z-index: 1000;
  width: ${props => props.theme.sidebarWidth};
  height: 100%;
  transform: ${props => (props.isOpen ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.2s ease-in;
  max-width: ${props => props.theme.sidebarWidth};;
  padding: 0 22px;

  @media ${device.tablet} {
    left: 0;
    padding: 0 22px;
    width: 50%;
  }

  & .item .title {
    position: relative;

    & .ui.active.loader {
      right: 0;
      left: inherit;
      transform: translate(0px, -50%);
    }
  }

  .close-accordion {
    display: none;
  }

  @media ${device.tablet} {
    .dropdown.icon {
      display: none !important;
    }
    .close-accordion {
      display: inline;
    }

    .active.title {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
    }
  }
`

const ListItem = styled(Menu.Item)`
  padding-left: 0 !important;
`

const AccordionTitle = ({ setExtended }) => (
  <React.Fragment>
    Kategori{" "}
    <span
      onClick={() => setExtended({ men: false, women: false })}
      className="close-accordion"
    >
      <Icon name="angle left" />
    </span>
  </React.Fragment>
)

function SlideIn({ isOpen, setExtended }) {
  const activeGender = isOpen.men ? "herre" : "dame"
  return (
    <Container isOpen={isOpen.men || isOpen.women}>
      <Accordion
        style={{
          borderRadius: 0,
          background: "transparent",
          boxShadow: "none",
          border: "none",
        }}
        as={Menu}
        vertical
      >
        <ListItem>
          <Accordion.Title
            active
            content={<AccordionTitle setExtended={setExtended} />}
            icon={null}
            index={0}
          />
          <Accordion.Content
            active
            content={<Category gender={activeGender} />}
          />
        </ListItem>{" "}
      </Accordion>
    </Container>
  )
}

export default SlideIn
